<template>
  <div class="violations-modal-edit-act">
    <div class="violations-modal-edit-act__block">
      <div
        v-for="field in mainActFields"
        :key="field.model"
        :class="[
          'violations-modal-edit-act__attribute',
          `${field.subType === 'employee' ? 'employee' : ''}`
        ]"
        :style="getFieldPosition(field)">
        <r-title
          v-if="field.subType === 'employee'"
          type="subtitle-2">
          {{ field.name }}
        </r-title>
        <r-text
          v-else
          color-type="secondary">
          {{ field.title || field.name }}
        </r-text>
        <el-select
          v-model="source[field.model]"
          filterable
          clearable
          :multiple="!!field.multiple"
          class="r-select"
          size="mini"
          :disabled="field.read_only"
          @input="$emit('has-changes')">
          <el-option
            v-for="(item, i) in source.related[field.source].data"
            :key="i"
            :value="item.id"
            :label="getLabel(field, item)" />
        </el-select>
      </div>
    </div>

    <div
      class="violations-modal-edit-act__block">
      <div class="violations-modal-edit-act__subtitle">
        <r-icon
          name="acts-document"
          :size="24" />
        <r-title type="subtitle-1">
          Акт-предписание
        </r-title>
      </div>
      <div
        v-for="field in prescriptionActFields"
        :key="field.model"
        class="violations-modal-edit-act__attribute"
        :style="getFieldPosition(field)">
        <r-text color-type="secondary">
          {{ field.title || field.name }}
        </r-text>
        <el-input
          v-if="isNativeTextField(field)"
          v-model="source[field.model]"
          class="r-input"
          :type="field.type"
          :autosize="{ minRows: 2, maxRows: 4 }"
          :rows="2"
          resize="none"
          clearable
          :disabled="field.read_only"
          @input="$emit('has-changes')" />
        <el-date-picker
          v-if="field.type === 'datetime'"
          v-model="source[field.model]"
          class="r-input"
          :type="field.type"
          clearable
          @input="$emit('has-changes')" />
        <el-select
          v-else-if="field.type === 'select'"
          v-model="source[field.model]"
          filterable
          clearable
          class="r-select"
          size="mini"
          :disabled="field.read_only"
          @input="$emit('has-changes')">
          <el-option
            v-for="item in source.related[field.source].data"
            :key="item.id"
            :value="item.id"
            :label="getLabel(field, item)" />
        </el-select>
      </div>
    </div>
    <div
      class="violations-modal-edit-act__block">
      <div class="violations-modal-edit-act__subtitle">
        <r-icon
          name="acts-document"
          :size="24" />
        <r-title type="subtitle-1">
          Акт-нарушение
        </r-title>
      </div>
      <div
        v-for="field in violationActFields"
        :key="field.model"
        class="violations-modal-edit-act__attribute"
        :style="getFieldPosition(field)">
        <r-text color-type="secondary">
          {{ field.title || field.name }}
        </r-text>
        <el-input
          v-if="isNativeTextField(field)"
          v-model="source[field.model]"
          class="r-input"
          :type="field.type"
          :autosize="{ minRows: 2, maxRows: 4 }"
          :rows="2"
          resize="none"
          clearable
          :disabled="field.read_only"
          @input="$emit('has-changes')" />
        <el-select
          v-else-if="field.type === 'select'"
          v-model="source[field.model]"
          filterable
          clearable
          :multiple="field.multiple"
          class="r-select"
          size="mini"
          :disabled="field.read_only"
          @input="$emit('has-changes')">
          <el-option
            v-for="item in source.related[field.source].data"
            :key="item.id"
            :value="item.id"
            :label="getLabel(field, item)" />
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { actFields } from '../config'

export default {
  props: {
    source: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      employeesArrayFields: [
        'violation_appointed_employees',
        'violation_representatives_employees'
      ]
    }
  },
  computed: {
    mainActFields() {
      return actFields.filter(f => f.actBlock === 'main')
    },
    prescriptionActFields() {
      return actFields.filter(f => f.actBlock === 'prescription')
    },
    violationActFields() {
      return actFields.filter(f => f.actBlock === 'violation')
    }
  },
  created() {
    this.formatArrayFields()
  },
  methods: {
    formatArrayFields() {
      this.employeesArrayFields.forEach(f => {
        this.source[f] = this.source[f]?.map(s => {
          if (s) {
            return s.employee_id
          }
        })
      })
    },
    getFieldPosition(field) {
      const width = {
        full: 6,
        half: 3,
        third: 2
      }
      const grid = `span ${width[field.width] ?? 3}`
      return `grid-column: ${grid};`
    },
    isNativeTextField(field) {
      return (
        (field.type === 'textarea' || field.type === 'text') &&
        field.sourceType === 'native'
      )
    },
    getLabel(field, item) {
      return field.label
        ? item[field.label]
        : (item.position ? `${item.name}, ${item.position}` : item.name) || '—'
    }
  }
}
</script>

<style lang="scss">
.violations-modal-edit-act {
  display: grid;
  grid-gap: 1rem;

  &__attribute {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;

    &.checkbox {
      display: grid;
      align-items: center;
      justify-content: center;

      .r-text.default,
      .r-checkbox {
        text-align: center;
        justify-content: center;
      }
    }

    .r-select,
    .r-input {
      width: 100% !important;
    }
  }

  &__subtitle {
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;
    grid-gap: 0.5rem;
    justify-items: start;
  }

  &__block {
    border: 1px solid var(--dividers_low_contrast);
    border-radius: var(--border-radius);
    background-color: var(--bg_containers);
    padding: 1rem;
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: row;
  }

  &__employees {
    border-radius: var(--border-radius);
    background-color: var(--bg_containers);
    padding: 0.5rem;
    display: grid;
    grid-gap: 1rem;
  }
}
</style>
